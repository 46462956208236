@import "src/scss/styles";

.program {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 300;
  justify-content: center;

  &__card {
    margin: 1rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 450px;
    height: 300px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    &> .program__header {
      display: block;
      width: 88%;
      max-width: 88%;
      height: 100%;
      background: #fff;
    }

    &:hover > .program__header + .program__button {
      max-height: 300px;
      -webkit-transition: max-height .5s ease-in;
      transition: max-height .5s ease-in-out;
      transition-delay: 200ms;
    }

    &:hover > .program__header + .program__button .program__button--text {
      -webkit-animation: fadein 2s;
      animation: fadein 2s;
    }

    h1 {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.2;
      margin: 4.5% 0 0 4.5%;
    }

    h2 {
      font-size: 12px;
      font-weight: 400;
      line-height: 1.5;
      margin: 0 0 0 4.5%;
      color: #cc3638;
    }
  }

  &__button {
    cursor: pointer;
    color: #fff;
    clear: both;
    overflow: hidden;
    box-sizing: border-box;
    max-width: 88%;
    width: 88%;
    max-height: 0;
    -webkit-transition: max-height .5s ease-out;
    transition: max-height .5s ease-out;
    background: #0f6dc5;
    -webkit-order: 1;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;

    &--text {
      margin: 4.5%;
      -webkit-animation: fadeout 2s;
      animation: fadeout 2s;
    }
  }

  &__header {
    min-width: 290px;
    max-width: 380px;
    min-height: 200px;
  }
}

.logo {
  display: -webkit-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
  max-width: 12%;
  width: 12%;
  margin: 0;
  padding: 3.5%;
  list-style-type: none;
  background-color: #cc3638;
  flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  -webkit-flex-direction: column-reverse;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -webkit-flex-pack: end;
  -ms-flex-pack: end;

  &__name {
    writing-mode: vertical-rl;
    text-orientation: upright;
  }
}

.header {
  &__detail {
    padding: 0 1rem;
    width: 380px;
    height: 200px;
  }

  &__image {
    padding: 0 1rem;
    width: 380px;
    height: 200px;
  }

  &__image > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@media only screen and (max-width: $screen-max-width) {
  .program {
    &__card {
      margin: 2rem 1rem;
      &:hover {
        z-index: 1000;
      }
    }
  }
}
