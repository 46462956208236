$background-color: #1d1d1d;

$nav-height: 5rem;
$nav-item-height: 1rem;
$nav-background-color: #24282b;

$screen-max-width: 995px;
$screen-min-width: 600px;

$field-color: rgb(40, 40, 40);
$field-size: 1rem;

$text-size: 18px;

$color-neutral: rgb(245, 245, 245);
//$color-default: #57DCBE;
$color-default: rgb(245, 245, 245);
$color-red: #E91E63;
$color-green: #60C689;
$color-blue: #41afdc;
$color-turquoise: #57DCBE;
$color-yellow: #F5B301;
$color-pink: #D77878;