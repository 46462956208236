@import 'styles';

.bms {
  &__field {
    position: absolute;
    color: $color-neutral;
    white-space: pre;
    margin: 0;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    letter-spacing: .3rem;
    font-size: $text-size;
    font-family: inherit;

    &--input, &--numeric, &--protected {
      height: $field-size;
      border: none;
      outline: none;
      box-shadow: none;
      background-color: $field-color;
      color: $color-neutral;
      text-align: left;
      text-transform: inherit;
      font-family: inherit;
      font-size: $text-size;
      letter-spacing:inherit;
    }

    &--numeric {
      color: $color-neutral;
      text-align: right;
    }

    &--protected {
      background-color: $background-color;
    }

    &--error {
      color: $color-red !important;
    }

    &--underscore {
      border-bottom: 1px solid $color-red !important;
    }

    &--blink {
      border: 1px solid $color-red;
      animation: blink 1s;
      animation-iteration-count: infinite;
    }
  }

  &__screen {
    padding: 2rem;
    width: calc(16px * 80);
    height: calc(1rem * 28);
    letter-spacing: 1px;
  }

  &__content {
    position: absolute;
  }

  &__text {
    &--neutral {
      color: $color-neutral;
    }

    &--default {
      color: $color-default;
    }

    &--red {
      color: $color-red;
    }

    &--green {
      color: $color-green;
    }

    &--blue {
      color: $color-blue;
    }

    &--turquoise {
      color: $color-turquoise
    }

    &--yellow {
      color: $color-yellow;
    }

    &--pink {
      color: $color-pink;
    }

    &--white {
      color: $color-neutral;
    }
  }

  &__tooltip {
    &--primary {
      margin-right: 1rem;
    }
    &--secondary {
      font-weight: bold;
    }
  }

  &__group {
    margin: 0;
    padding: 0;
    &--row {
      margin: 0;
      padding: 0;
    }
  }
}

@keyframes blink { 50% { border-color:$background-color ; }  }
