@import 'variables';

body {
  padding: 0;
  margin: 0;
}

.root {
  width: 100%;
  height: 100vh;
  background: $background-color;
  //font-family: Montserrat, monospace, courier, Arial, Helvetica, sans-serif;
  font-family: "Courier New", Courier, monospace;
  font-size: 16px;
}

.main {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-content: center;
}

.uppercase {
  text-transform: uppercase;
}

.mixed {
  text-transform: none !important;
}

.container {
  display: flex;
  justify-content: center;
  align-content: center;
}

.loading {
  height: 5px;
  width: 100%;
}