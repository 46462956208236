@import "variables";

.virtual-keyboard {
  width: 100%;
  height: 64px;
}

.keyboard {
  z-index: 100;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;

  &__row {
    width: 100%;
    padding: 0 1rem;
    display: flex;
    justify-content: center;
  }

  &__key {
    display: inline;
    margin: 0;
    padding: 0;
    width: 4rem;
  }

  &__toggle {
    cursor: pointer;
    margin: 1rem 0;
    padding: 0;
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-content: center;
    &--icon {
      color: $color-neutral;
    }
  }
}

.function {
  &__button {
    cursor: pointer;
    width: 4rem;
    height: 2rem;
    font-size: 11pt;
    border: 0;
    border-radius: 0;
    line-height: 0;
  }
}

.tray {
  position: absolute;
  top: calc(1rem * 32);
  left: 0;
  width: 100%;
}