@import 'styles';

.nav {
  background-color: $nav-background-color;

  &__container {
    padding: 0;
    margin: 0;
    box-shadow: none;
    height: $nav-height;
  }

  &__logo {
    z-index: 100;
    position: fixed;
    display: flex;
    left: $nav-item-height;
  }
}
